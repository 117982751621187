/**
 * Definition for bundle. See source for details.
 *
 * @class Oskari.elf.lang.overrides.Bundle
 */
Oskari.clazz.define("Oskari.tilastokeskus.language.Bundle", function () {}, {
  "create": function create() {
    return this;
  },
  "start": function start() {},
  "stop": function stop() {}
}, {
  "source": {
    "scripts": [],
    "locales": [{
      "lang": "fi",
      "type": "text/javascript",
      "src": "../../../bundles/tilastokeskus/lang-overrides/resources/locale/fi.js"
    }, {
      "lang": "sv",
      "type": "text/javascript",
      "src": "../../../bundles/tilastokeskus/lang-overrides/resources/locale/sv.js"
    }, {
      "lang": "en",
      "type": "text/javascript",
      "src": "../../../bundles/tilastokeskus/lang-overrides/resources/locale/en.js"
    }]
  }
});
Oskari.bundle_manager.installBundleClass("lang-overrides", "Oskari.tilastokeskus.language.Bundle");
